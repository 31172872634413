//
// Global CSS
//


// apply a natural box layout model to all elements, but allowing components to change
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}


// ----- HTML-Elemente -----

body {
  overflow-x: hidden;
  margin: 0;
  font-family: $primary-font;
  @include font-size($font-size-S);
  color: $grey;

  @media only screen and (min-width: $size-M2) {
    @include font-size($font-size-M);
  }
}

img {
  border-style: none;
}

h1 {
  font-family: $secondary-font;
  font-weight: 300;
  @include font-size($font-size-XXL);
  color: var(--secondary-color);
  line-height: 1.5;

  @media only screen and (max-width: $size-L) {
    @include font-size($font-size-XL);
  }

  @media only screen and (max-width: $size-S2) {
    @include font-size($font-size-L);
  }
}

h2 {
  font-family: $secondary-font;
  @include font-size($font-size-XXL);
  color: var(--secondary-color);
  margin-top: 0;
  margin-bottom: 0.4em;
  line-height: 1.5;
  font-weight: 300;
}

h3 {
  font-family: $secondary-font;
  font-weight: 400;
  color: var(--secondary-color);
  line-height: 1.5;
}

// set headline to be centred mostly
main {
  header {
    h1,
    h2 {
      text-align: center;
    }
  }
}

// with an exception for text image CEs
main {
  .ce-text-image-text {
    header {
      h1,
      h2 {
        text-align: left;
      }
    }
  }
}


p {
  margin: 0 0 1em 0;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: $black;
}

// external text links
p a[target='_blank'] {
  &::before {
    content: "";
    width: 20px;
    height: 20px;
    background: url("../Images/icon_external_link.svg") no-repeat left bottom;
    background-size: 20px 20px;
    margin-right: 5px;
    margin-left: 2px;
    margin-bottom: -3px;
    display: inline-block;
  }
}

.ce-inner,
.inner,
.container {
  margin-left: auto;
  margin-right: auto;
  max-width: $maxPageInnerWidth;
  padding-left: $gap-M;
  padding-right: $gap-M;

  @media only screen and (min-width: $size-S) {
    padding-left: $gap-L;
    padding-right: $gap-L;
  }
}

.flex-row {
  display: flex;
  flex: 1;
}

.more-link {
  &:focus,
  &:focus-visible {
    outline: 2px dotted $grey;
    outline-offset: 5px;
  }

  a {
    &:focus,
    &:focus-visible {
      outline: 2px dotted $grey;
      outline-offset: 5px;
    }
  }
}


// set some elements responsive
img, object, embed, video {
  height: auto;
  max-width: 100%;
}

// and for video
.video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  @media (max-width: 979px) {
    margin-bottom: 20px;
  }
}

.video iframe,
.video object,
.video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Prevents img without src to appear
img:not([src]) {
  visibility: hidden;
}

// fix for MS Edge to fire the IntersectionObserver for an img element w/o size
img[data-src],
img[data-srcset] {
  display: block;
  min-height: 1px;
}

// ----- helper css classes -----

.clearfix {
  @include clearfix;
}

.hide-text {
  @include hide-text;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  @include list-inline;
}

.invisible {
  display: none !important;
}

.visually-hidden {
  @include visually-hidden;
}

.no-margin {
  margin: 0 !important;
}

.right-float {
  float: right;
}

.vertical-align-sub {
  vertical-align: sub;
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-middle {
  vertical-align: middle;
}

.text-align-center,
.align-center {
  text-align: center;
}

.text-align-left,
.align-left {
  text-align: left;
}

.text-align-right,
.align-rigtht {
  text-align: right;
}
