//
// Footer CSS
//

.page-footer {
  background: var(--secondary-color);
  color: $white;
  padding: 30px 0;
  $flex-gap: 20px;

  .page-footer-cols {
    display: flex;
    flex-flow: row wrap;
    margin: -$flex-gap;
  }

  .footer-col {
    width: 100%;
    padding: $flex-gap;

    @media only screen and (min-width: $size-S2) {
      width: calc(100% / 2);
    }

    @media only screen and (min-width: $size-M4) {
      width: calc(100% / 4);
    }
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: $white;
  }

  p {
    @include font-size(16);
  }

  a {
    text-decoration: none;
    color: $white;

    &:hover {
      text-decoration: underline;
    }

    &:focus-visible {
      outline: 2px dotted;
      outline-offset: 2px;
      border-radius: 2px;
    }
  }

  .contact-icons {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .footer-col-visit {
    .location {
      display: block;

      svg {
        width: 40px;
      }
    }
  }

  .footer-col-visit {
    .email {
      display: block;

      svg {
        width: 60px;
      }
    }
  }

  .footer-col-logos {
    max-width: 360px;

    img {
      display: block;

      &:first-child {
        margin-bottom: 5px;
      }
    }

    .logo-2-cols {
      display: flex;
      gap: 5px;

    }
  }

}