//
// CE Menu Pages CSS
//

.ce-menu_pages {

  header {
    position: relative;
    background: var(--primary-color);
    padding-bottom: 100px;

    h1 {
      margin: 0;
      padding-bottom: $gap-M2;
      color: $white;
      text-align: left;
      line-height: 1.2;
      max-width: 820px;
      font-weight: 300;
    }
  }


  &--items {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    margin: -100px;
    margin-top: -100px;

    article {
      padding: $gap-L;
      max-width: 500px;

      img {
        width: 100%;
        max-width: none;
      }
    }

    img {
      border-radius: 6%;
    }

    @media only screen and (max-width: $size-S3) {
      justify-content: center;
      margin-left: -40px;
      margin-right: -40px;
    }

    @media only screen and (min-width: $size-S3) {
      margin: -$gap-S;
      margin-top: -100px;

      article {
        width: calc(100% / 2);
        padding: $gap-M $gap-S;
      }

    }

    @media only screen and (min-width: $size-L) {

      article {
        width: calc(100% / 4);
      }

    }

  }


  &--item {

    .more-link {
      display: inline-block;
      margin-top: 30px;
      margin-left: 30px;
      border-radius: 24px;
      border: 2px solid var(--secondary-color);
      text-transform: uppercase;
      padding: 8px 20px;
      text-decoration: none;
      @include font-size($font-size-XS);
      font-weight: 700;
      transition: background-color 200ms;
      white-space: nowrap;
      color: $grey;

      &:hover {
        background: var(--secondary-color);
        color: $white;
      }
    }
  }

  &--item-text {
    padding: 0 30px;
    hyphens: auto;
    @include font-size($font-size-S);

    h2 {
      margin-top: 0.4em;
      line-height: 1.4;
      @include font-size($font-size-M2);
      text-transform: uppercase;
    }

    @media only screen and (min-width: $size-M2) {
      padding: 0 10px;
    }

    @media only screen and (min-width: $size-L) {
      @include font-size($font-size-M);

      h2 {
        @include font-size($font-size-L);
      }
    }

    @media only screen and (min-width: $size-L2) {
      padding: 0 30px;
    }

  }


}



