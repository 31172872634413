//
// Project Color Definitions
//

$primary-color: #4057b0;
$secondary-color: #4057b0;

:root {
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
}


// General Colors
$green: #94B81A;
$green-light: #E4ECC5;

$blue: #A3D0ED;
$blue-light: #E6F3FB;

$grey-dark: #333333;
$grey: #666666;
$grey-light: #d0d0d0;
$grey-super-light: #F9F8F6;

$white: #FFFFFF;
$black: #000000;