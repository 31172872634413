//
// CE Hero Image CSS
//

.ce-hero_image {
  position: relative;

  figure {
    position: relative;
  }

  img {
    display: block;
    max-width: none;
    width: 100%;
  }

  figcaption {
    padding: 20px 0;
    background: rgba($white, .7);

    @media only screen and (min-width: $size-M) {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    @media only screen and (max-width: $size-S2) {
      .flex-row {
        flex-flow: column;
      }
    }

    h1 {
      margin: 0;
    }

    .flex-row {
      justify-content: space-between;
      align-items: center;
    }
  }

  .ce-hero-image-caption-text {
    padding-right: $gap-M;
    hyphens: auto;
    color: $grey-dark;
  }

  .ce-hero-image-caption-title {
    @include font-size($font-size-XXXL);
    color: var(--secondary-color);
    font-family: $secondary-font;
    line-height: 1.4;
    margin-bottom: 0;
    hyphens: auto;
    font-weight: 300;
  }

  .more-link {
    display: inline-block;
    margin-top: 20px;
    border-radius: 24px;
    border: 2px solid $green;
    text-transform: uppercase;
    padding: 8px 20px;
    text-decoration: none;
    @include font-size($font-size-XS);
    font-weight: 700;
    transition: background-color 200ms;
    background: $green;
    color: $white;
    white-space: nowrap;

    &:hover {
      background: $white;
      color: var(--secondary-color);
    }
  }


  .hero-type-video {
    position: relative;

    .hero-image-title {
      position: absolute;
      left: 12%;
      bottom: 30%;
      @include font-size($font-size-XXXL);
      font-weight: 300;
      text-transform: uppercase;
      color: $white;
      line-height: 1;

      a {
        position: relative;
        display: block;
        padding-left: 160px;
        padding-top: 20px;
        text-decoration: none;
        color: $white;

        &::before {
          position: absolute;
          left: 0;
          top: 0;
          content: "";
          width: 120px;
          height: 120px;
          background: url("../Images/play_button.png") no-repeat;
          background-size: 120px;
        }
      }

      @media only screen and (max-width: $size-L) {
        @include font-size($font-size-L);
        left: $gap-M;

        a {
          padding-left: 80px;

          &::before {
            top: 15px;
            width: 60px;
            height: 60px;
            background-size: 60px;
          }
        }
      }

      @media only screen and (max-width: $size-S3) {
        @include font-size($font-size-L);
        left: $gap-M;

        a {
          padding-left: 60px;

          &::before {
            width: 40px;
            height: 40px;
            background-size: 40px;
          }
        }
      }

    }

    .youtube-consent {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba($grey-light, .8);
      justify-content: center;
      align-items: center;
      display: none;

      &.show {
        display: flex;
      }

      &-inner {
        background: #e7e7e7;
        color: $grey-dark;
        padding: 30px 40px;
        border: 2px solid $grey-dark;
        @include font-size($font-size-S);

        a {
          color: $grey-dark;
          text-decoration: underline;
        }
      }

      .btn-group {
        margin-top: 20px;

        button {
          border: none;
          padding: 8px 20px;
          background: #666666;
          color: $white;
          cursor: pointer;

          &.btn-yt-confirm {
            margin-right: 10px;
          }

          &:hover {
            background: lighten(#666666, 10);

            &.btn-yt-confirm {
            }
          }
        }
      }
    }

  }


  @media only screen and (max-width: $size-L) {
    @include font-size($font-size-S);

    .ce-hero-image-caption-text {
      @include font-size($font-size-S);
    }

    .ce-hero-image-caption-title {
      @include font-size($font-size-L);
    }
  }

}