//
// Header CSS
//

.page-header {
  position: relative;
  background: $grey-super-light;
  z-index: 5;

  .flex-row {
    justify-content: space-between;
  }

  .logo {
    display: flex;
    align-items: center;
    padding-right: $gap-M;

    img {
      display: block;
      width: 240px;
    }

    a {
      &:focus-visible {
        outline: 2px dotted;
        outline-offset: 5px;
        border-radius: 5px;
      }
    }

    @media only screen and (max-width: $size-L2) {
      width: 160px;
    }
  }

  .logo-vohburg {
    display: flex;
    align-items: center;

    a {
      &:focus-visible {
        outline: 2px dotted;
        outline-offset: 5px;
        border-radius: 5px;
      }
    }

    img {
      display: block;
      width: 150px;
    }

    @media only screen and (max-width: $size-L2) {
      padding-right: 80px;

      img {
        width: 100px;
      }
    }

    @media only screen and (max-width: $size-S) {
      padding-right: 60px;
    }
  }
}

.page-header-main {
  display: flex;
  height: 80px;

  @media only screen and (min-width: $size-L2) {
    height: 160px;
  }
}