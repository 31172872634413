//
// CE Spacing CSS
//

// defaults
main [class*="ce-uid"] {
  margin-top: 80px;
  margin-bottom: 80px;

  &[class*="ce-color"] {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

main {

  // set margin top default for very ce-hero-slider and hero-image to 0
  .ce-hero_slider,
  .ce-hero_image {
    &:first-child {
      margin-top: 0;
    }
  }

  .ce-space-before-none {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .ce-space-before-extra-small {
    margin-top: 20px;
  }

  .ce-space-before-small {
    margin-top: 40px;
  }

  .ce-space-before-medium {
    margin-top: 80px;
  }

  .ce-space-before-large {
    margin-top: 100px;
  }

  .ce-space-before-extra-large {
    margin-top: 120px;
  }

  .ce-space-after-none {
    margin-bottom: 0 !important;
  }

  .ce-space-after-extra-small {
    margin-bottom: 20px;
  }

  .ce-space-after-small {
    margin-bottom: 40px;
  }

  .ce-space-after-medium {
    margin-bottom: 80px;
  }

  .ce-space-after-large {
    margin-bottom: 100px;
  }

  .ce-space-after-extra-large {
    margin-bottom: 120px;
  }


  // use padding instead of margin on bg colors
  section[class*="ce-color"] {

    &.ce-space-before-extra-small {
      padding-top: 20px;
    }

    &.ce-space-before-small {
      padding-top: 40px;
    }

    &.ce-space-before-medium {
      padding-top: 80px;
    }

    &.ce-space-before-large {
      padding-top: 100px;
    }

    &.ce-space-before-extra-large {
      padding-top: 120px;
    }

    &.ce-space-after-extra-small {
      padding-bottom: 20px;
    }

    &.ce-space-after-small {
      padding-bottom: 40px;
    }

    &.ce-space-after-medium {
      padding-bottom: 80px;
    }

    &.ce-space-after-large {
      padding-bottom: 100px;
    }

    &.ce-space-after-extra-large {
      padding-bottom: 120px;
    }
  }

}